
.flex-wrap {
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}

.footer {
  .footerBanner {
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #31335c;

    .fotbaner {
      overflow: hidden;
      width: 970px;
      height: 250px;
      img {
        width: auto;
        height: 100%;
        object-fit: cover;
        object-position: top center;
        @media screen and (min-width: 768px) and (max-width: 1020px) {
          width: 100%;
        }
      }
    }
  }

  .bodyBox {
    background: #2e305c;
    padding: 20px 32px 20px 32px;
    color: white;

    .boxInner {
      .footer-mobile {
        text-align: center;
        display: none;
        @media screen and (max-width: 767px) {
          display: block;
          margin-bottom: 20px;
        }
        h1 {
          margin-top: 0;
          font-style: normal;
          font-weight: 400;
          font-size: 37.57px;
          line-height: 45px;
          margin-bottom: 8px;

          span {
            font-weight: 800;
            color: #fa8c28;
          }
        }
        p {
          font-size: 13px !important;
        }

        .right {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          margin-top: 25px;
          margin-bottom: 25px;

          p {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            text-transform: uppercase;
            margin: 0;
            margin-bottom: 4px;
            text-align: left;
          }

          .line {
            width: 63px;
            height: 2px;
            background-color: #fa8c28;
          }

          ul {
            -webkit-padding-start: 0px;
            padding-inline-start: 0px;
            margin-top: 10px;
            text-align: left;

            li {
              font-weight: 400;
              font-size: 9px;
              line-height: 20px;
              list-style: none;
              text-decoration: none;
              cursor: pointer;
              margin-top: 4px;

              a {
                font-style: normal;
                font-weight: 400;
                font-size: 9px;
                line-height: 20px;
                text-transform: uppercase;
                color: #fff;
                text-decoration: none;
              }
            }
          }
        }
      }
      .innerLink {
        display: flex;
        flex-direction: column;

        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        p {
          font-size: 14px !important;
          span {
            color: #fa8c28;
            cursor: pointer;
          }
        }
        .followUs {
          display: flex;
          align-items: center;
          @media screen and (max-width: 767px) {
            border-bottom: #fff solid thin;
            padding-bottom: 15px;
            margin-bottom: 15px;
            max-width: 100%;
            width: 100%;
            justify-content: center;
          }

          p {
            font-size: 16px !important;
            font-weight: 700;
            margin-right: 10px;
          }

          img {
            width: 25px;
            margin-left: 4px;
            cursor: pointer;
          }
        }

        ul {
          display: flex;

          list-style: none;
          color: rgb(201, 201, 201);
          margin: 6px auto;
          @media screen and (max-width: 767px) {
            display: none;
          }
          li {
            margin: auto 4px;
            cursor: pointer;
          }

          li:hover,
          a:hover {
            color: #fa8c28;
          }

          a {
            text-decoration: none;
            color: rgb(201, 201, 201);
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .footerBanner {
      display: none !important;
    }
    .bodyBox {
      padding: 15px;
    }
  }
}
